import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard  {
  constructor(private router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.userService.currentUserValue;
    const roles = route.data.roles as Array<number>;
    if (!user) {
      this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
      return false;
    }

    if (!roles && this.userService.isCurrentUserMember) {
      this.router.navigate(['/']);

      return false;
    }
    if (roles && !roles.includes(user.role.id)) {
      this.router.navigate(['/']);

      return false;
    }
    return true;
  }
}
